
import { Route, Routes } from "react-router-dom";
import React, { useState } from 'react'
import Frageverktyg from './pages/Frageverktyg';
import MatchingCourses from './pages/MatchingCourses';
import Course from './pages/Course';

import Footer from './components/Footer';
import ContactPage from "./pages/ContactPage";
import Navbar01 from "./components/Navbar01";
import BookingConfirmation from "./pages/BookingConfirmation";
import NotFound from "./pages/NotFound";
function App() {
  const [summary, setSummary] = useState([])
  const [userName, setUserName] = useState("")


  return (
    <div className="">
      <Navbar01 />
      <Routes>

        <Route path="/" element={<Frageverktyg />} />
        <Route path="/frageverktyg" element={<Frageverktyg />} />

        <Route path="/kursval/:id" element={<Course setSummary={setSummary} />} />
        <Route path="/matchandekurser" element={<MatchingCourses />} />
        <Route path="/vikontaktardig" element={<ContactPage />} />
        <Route path="/bokningsbekraftelse" element={<BookingConfirmation summary={summary} />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <div className="" style={{ height: "185px" }} />
      <Footer />

    </div>
  );
}

export default App;
