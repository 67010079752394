import React, { useEffect, useState } from 'react'
import axios from "axios";
import Questions from './Questions';
import DoNotKnowModal from '../components/DoNotKnowModal';
import Row from 'react-bootstrap/Row';

import { useNavigate } from "react-router";

function Frageverktyg() {
    const navigate = useNavigate();

    const [questions, setQuestions] = useState("");
    const [title, settitle] = useState("");
    const [answerOne, setAnswerOne] = useState({ aid: 0 })

    const [allInfo, setAllInfo] = useState("")
    const [customerOrder, setcustomerOrder] = useState({ base: "", toppings: [] });
    const [loading, setLoading] = useState(true)

    const [show, setShow] = useState(false);

    //get question 2
    const [next, setNext] = useState("")

    let data = {
        "Answer_1": answerOne.aid,
        "Answer_2": customerOrder.toppings,
        "Answer_3": [],
    };

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    }


    const addNeeds = (topping) => {
     

        if (topping === 1018 ||
            topping === 1026 ||
            topping === 1010) {
            handleShow();
        }


        let newToppings = [];
        if (!customerOrder.toppings.includes(topping) || !customerOrder.toppings === 1010) {

            newToppings = [...customerOrder.toppings, topping];

        }
        if (customerOrder.toppings.includes(topping)) {
            newToppings = customerOrder.toppings.filter(item => item !== topping);
            if (customerOrder.toppings.length === 3) {
                setcustomerOrder({ ...customerOrder, toppings: newToppings });
            }
        }
        if (customerOrder.toppings.length < 3) {
            setcustomerOrder({ ...customerOrder, toppings: newToppings });
        }
        localStorage.setItem("ans1", answerOne.aid);
        
    }


    const getObjects = async () => {
        
        if(answerOne.aid === 1002){
            navigate("/kursval/2031");
        }

        const config = {
            method: "post",
            url: `${process.env.REACT_APP_BASEURL}/questions`,
            headers: {
                "Content-Type": "application/json",
                "token": "KryastFAQ_Nykel_Mars2023"
            },
            data: JSON.stringify(data)
        }


        axios(config)
            .then(res => {
               
                setAllInfo(res.data.answers[0].fieldType)
                settitle(res.data.question.title)
                setQuestions(res.data.answers)
                setLoading(false)
            })
    }


    useEffect(() => {
        window.scrollTo(0, 0)
        getObjects();
    }, [next])

    useEffect(() => {
        // setLoading(true);
        //USER SELECTED "ANNAT/VET EJ"
        localStorage.setItem("ans2", JSON.stringify(customerOrder.toppings));
    }, [customerOrder.toppings])
    return (
        <>
            <Row>
                <DoNotKnowModal show={show} handleClose={handleClose} />
            </Row>
            <div className=" space-block "></div>
            <div className="my-5">
                <div className="bg-lightgrey">
                    <div className="container">

                        <div className="text-center">
                            {loading &&
                                <div className="spinner-border mt-5" style={{ color: "#74474d" }} role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>}
                        </div>

                        <Questions
                            handleClose={handleClose}
                            handleShow={handleShow}
                            questions={questions}
                            setAnswerOne={setAnswerOne}
                            answerOne={answerOne}
                            title={title}
                            setNext={setNext}
                            allInfo={allInfo}
                            addNeeds={addNeeds} customerOrder={customerOrder} />
                    </div>

                </div>
            </div>

        </>
    )
}

export default Frageverktyg
