import React from 'react'
import warning from "../images/Warning.png"
function NotFound() {
    return (
        <>
            <div className="space-block mt-5"></div>
            
            <div className="mt-4 bg-lightgrey text-center py-5">
                <h3 className="p-2">
                    <img src={warning}
                        alt="Pratbubbla gul"
                        style={{ width: "70px", height: "70px" }} />
                </h3>
                <h1 className="fc-headline">HOPPSAN!</h1>
                <p className="fs-3">Sidan du söker kan inte hittas.</p>
                <a className="fc-yellow text-decoration-none" href="https://www.kryast.se">Till startsidan</a>
            </div>
        </>
    )
}

export default NotFound
