import React, { useRef } from 'react'
import "../styles/navbarStyle.css"

function Navbar01() {
  const navRef = useRef();
  const showNavbar = () => {
    navRef.current.classList.toggle("responsive-nav");
  }

  return (
    <>
      <div className="top-nav"></div>
      <header className="">
        <a href="https://kryast.se" className="logo col-2 col-12-sm ms-3 mt-3"></a>
        <button className="nav-btn-ham" onClick={showNavbar}>
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
          </svg>
        </button>
        <nav ref={navRef}>
          <a href="https://kryast.se/hallbar-arbetsplats/">För Arbetsplatsen</a>
          <a href="https://kryast.se/ledarskapsutbildning/">För ledare</a>
          <a href="https://kryast.se/medarbetare/">För medarbetare</a>
          <a href="https://kryast.se/konferens/">Konferens</a>
          <a href="/frageverktyg" className="text-dark">
            <button className="btn btn-light ms-3 rounded-5 px-4">Frågeverktyg</button>
          </a>
          <button className="nav-btn nav-close-btn" onClick={showNavbar}>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
            </svg>
          </button>
        </nav>

      </header>

    </>
  )
}

export default Navbar01
