import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';

//BOOTSTRAP
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import imageLogo from "../images/Pratbubbla.png"
import ConfirmComponent from '../components/ConfirmComponent';
function BookingConfirmation() {

    let navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [sendRequest, setSendRequest] = useState(false);
    const [orderNumber, setOrderNumber] = useState("");

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let summary = sessionStorage.getItem("supplements").split(',');
    let summaryArray = JSON.parse(summary)
    const [confirmContent, setConfirmContent] = useState([])

    const runComfirmation = async () => {
        const config = {
            method: "post",
            url: `${process.env.REACT_APP_BASEURL}/questions/summary`,
            headers: {
                "Content-Type": "application/json",
                "token": "KryastFAQ_Nykel_Mars2023"
            },
            data: JSON.stringify(summaryArray)
        }
        axios(config)
            .then(res => {
                // console.log(res.data)
                setConfirmContent(res.data)
            })
    }

    const sendConfirmation = async () => {
        const config = {
            method: "post",
            url: `${process.env.REACT_APP_BASEURL}/order-confirmation`,
            headers: {
                "Content-Type": "application/json",
                "token": "KryastFAQ_Nykel_Mars2023"
            },
            data: JSON.stringify(confirmContent)
        }

        axios(config)
            .then(res => {
                // console.log(res.data)
                setOrderNumber(res.data.orderID)
                setSendRequest(true);
                handleShow();
            })
    }

    const confirm = () => {
        // window.print();

        sendConfirmation();

    }

    useEffect(() => {
        runComfirmation();
        window.scrollTo(0, 0);


    }, [])
    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                size="lg">
                <Modal.Header closeButton className="text-center">

                </Modal.Header>
                <Modal.Body className="text-center">
                    <h3>
                        <img src={imageLogo}
                            alt="Pratbubbla gul"
                            style={{ width: "50px", height: "50px" }} />
                    </h3>
                    <h4 className="fw-bolder mt-4 mb-4">Tack för din bokning!</h4>

                    <p>Vi kommer att höra av oss inom kort. Du kommer få dina bokningsuppgifter via den mejl du angav.

                    </p>
                    <h5 className="mt-3">Bokningsnummer: {orderNumber && orderNumber}</h5>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                        className="rounded-5 px-4">
                        Stäng
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className="space-block"></div>

            <div className="bg-lightgrey mt-4">
                <div className="container p-md-5 p-2 bg-lightgrey">
                    <h1 className="mx-4 fc-headline text-center pb-3">Preliminärboknings förfrågan</h1>

                    <Card className="mb-5">
                        <Card.Header className="p-3">
                            <h5 className="">Bekräfta din preliminärbokning</h5>
                            <small>(Denna bokningen är inte bindande)</small></Card.Header>
                        <Card.Body className="mx-3">
                            {confirmContent &&
                                <ConfirmComponent confirmContent={confirmContent} />
                            }

                            <hr />
                        </Card.Body>

                        <div className="next d-flex justify-content-end mx-2 mb-3 p-3">
                            <Button variant="secondary"
                                className="rounded-5 px-3 me-2"
                                onClick={() => navigate(-1)}>
                                Tillbaka
                            </Button>
                            {sendRequest ?
                                ""
                                :
                                <Button
                                    className="bg-yellow text-dark border-0 rounded-5"
                                    onClick={() => confirm()}>
                                    Bekräfta
                                </Button>}

                        </div>
                        {!sendRequest ?
                            "" :
                            <p className="text-center">Din bokningsförfrågan är skickad och kommer behandlas av oss inom kort.  <a className="fc-yellow text-decoration-none" href="https://www.kryast.se">Till startsidan</a></p>}
                    </Card>

                </div>

            </div>
        </>
    )
}

export default BookingConfirmation
