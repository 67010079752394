import React, { useState, useEffect } from 'react';
import Lokal from "../images/Lokal.jpg"

//Bootstrap
import Image from 'react-bootstrap/Image';

function CourseContext({ courses }) {
    const getScript = () => {
        const script = document.createElement("script");
        script.src = "https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha3/dist/js/bootstrap.bundle.min.js";
        script.async = true;
        document.getElementById("course-container").appendChild(script);
    }
    useEffect(() => {
        if (courses) {
            getScript();
        }
    }, [courses])

    return (
        <>
            {!courses ?
                <div className="text-center">
                    <div className="spinner-border mt-5" style={{ color: "#74474d" }} role="status">
                        {/* <span classNAme="visually-hidden">Loading...</span> */}
                    </div>
                </div> :
                <section id='course-container'>
                    <h1 className=" fc-headline font-headline">{courses && courses.title}</h1>
                    <Image
                        src={courses.imageUrl}
                        fluid />
                    {courses &&
                        <>
                            <div
                                className="mt-4"
                                dangerouslySetInnerHTML={{ __html: courses.description }}></div>
                        </>}
                </section>}
        </>
    )
}

export default CourseContext
