import React from 'react';
//BOOTSTRAP
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import imageLogo from "../images/Pratbubbla.png"
function WeContactYouModal({ show, handleClose, textModal }) {
    return (

        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
            size="lg">
            <Modal.Header closeButton className="text-center">
                <div className="text-center"></div>
            </Modal.Header>
            <Modal.Body className="text-center">
                <h3>
                    <img src={imageLogo}
                        alt="Pratbubbla gul"
                        style={{ width: "50px", height: "50px" }} />
                </h3>
                <h1 className="pb-2 pt-2 mt-3 fc-headline font-headline">Tack!</h1>

                <p className="pb-4">
                    {textModal}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={handleClose}
                    className="rounded-5 px-4">
                    Stäng
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default WeContactYouModal
