import React, { useState, useEffect } from 'react'
import SupplementModal from './SupplementModal';
import Info from './svg/Info';

function SupplementInfo({ sup1,
    sup2,
    options,
    onChangeHandler,
 }) {
    const [show, setShow] = useState(false);
    const [text, setText] = useState("");
   

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const getSuppInfo = async (supplement) => {
        setShow(true)
        setText(supplement)
    }






    return (
        <>
            <SupplementModal show={show} handleClose={handleClose} text={text} />
            <div className="row" >
                {sup1.length !== 0 &&
                    <div className="col-5">
                        <label htmlFor="exampleInputPassword1" className="form-label text-muted mt-1">{(sup1.length > 0) && "Tillval"}</label>
                        {(options && sup1) && sup1.map((supplement, index) => (
                            <div className="mb-1 form-check" key={index}>
                                <input
                                    name="SelectedSupplements"
                                    type="checkbox"
                                    className="form-check-input"
                                    id={supplement.title}
                                    value={supplement.title}
                                    onChange={onChangeHandler} />
                                <div className=" p-0 ms-1 mb-1 m-0"
                                // onClick={() => getSuppInfo(supplement)}
                                >
                                    <label
                                        className="form-check-label me-1"
                                        style={{ fontSize: "14px" }}
                                    >{supplement.title}</label>
                                    {supplement.description.length > 2 &&
                                        <div className="btn p-0"
                                            onClick={() => getSuppInfo(supplement)}
                                        >
                                            <Info /></div>
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                }
                <div
                    className="col-7">
                    <label
                        htmlFor="exampleInputPassword1"
                        className="form-label text-muted mt-1">{(sup2.length > 0) && "Teamaktivitet"}</label>
                    {options && sup2.map((supplement, index) => (
                        <div className="mb-1 form-check" key={index}>
                            <div className="d-flex p-0">
                                <input
                                    name="SelectedSupplements"
                                    id={supplement.title}
                                    type="checkbox"
                                    className="form-check-input"
                                    value={supplement.title}
                                    onChange={onChangeHandler} />
                                <div className=" p-0 ms-1 mb-1 m-0"
                                // onClick={() => getSuppInfo(supplement)}
                                >
                                    <label
                                        className="form-check-label me-1"
                                        style={{ fontSize: "14px" }}
                                    >{supplement.title}</label>
                                    <div className="btn p-0"
                                        onClick={() => getSuppInfo(supplement)}
                                    >
                                        <Info /></div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default SupplementInfo
