import React, { useEffect, useState } from 'react';
//BOOTSTRAP
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function TeamActivitiesModal({ show, handleClose, clickedOnThisID }) {
    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
            size="lg">
            <Modal.Header closeButton className="text-center">
                <div className="text-center"></div>
            </Modal.Header>
            <Modal.Body className="text-center">
                <h6>
                    Aktivitets bild? En och samma för alla?
                    {/* <img src={imageLogo}
                        alt="Pratbubbla gul"
                        style={{ width: "50px", height: "50px" }} /> */}
                </h6>
                <h3 className="pb-2 pt-2 mt-3 fc-headline font-headline">
                    {clickedOnThisID === "1" && <>Mästarens Mästare</>}
                    {clickedOnThisID === "2" && <>Backpacker</>}
                    {clickedOnThisID === "3" && <>The TV-SHOW</>}
                    {clickedOnThisID === "4" && <>Life Kinetik</>}

                </h3>

                <p className="pb-4">
                    kort html info om aktivitet
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={handleClose}
                    className="rounded-5 px-4">
                    Stäng
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default TeamActivitiesModal
