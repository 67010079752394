import React, { useEffect, useState } from 'react';
import WeContactYouModal from '../components/WeContactYouModal'
import imageLogo from "../images/Pratbubbla.png";
import axios from "axios";

function ContactPage() {
    const [show, setShow] = useState(false);
    const [name, setName] = useState("");
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [text, setText] = useState("");

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let data = {
        logID: "",
        logType: "ContactMe",
        text: `Namn:${name}, Text:${text}`,
        mobile: mobile,
        email: email,
        status: ""
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const config = {
            method: "post",
            url: `${process.env.REACT_APP_BASEURL}/update-log`,
            headers: {
                "Content-Type": "application/json",
                "token": "KryastFAQ_Nykel_Mars2023"
            },
            data: JSON.stringify(data)
        }
        axios(config)
            .then(res => {
                // setCoursesApi(res.data.courses)
               
            })

        setEmail("");
        setName("");
        setMobile("");
        setText("");
        handleShow();
    }
    return (
        <>
            <WeContactYouModal show={show} handleClose={handleClose} textModal="För att du kontaktat oss, vi kommer återkomma till dig inom kort."/>
            <div className=" space-block "></div>
            <div className="my-5">
                <div className="bg-lightgrey pb-5">
                    <div className="container text-center ">
                        <h3 className="pt-5">
                            <img src={imageLogo}
                                alt="Pratbubbla gul"
                                style={{ width: "70px", height: "70px" }} />
                        </h3>
                        <h1 className="pt-5 pb-4 fc-headline font-headline">Vi kontaktar dig</h1>



                        <form className="d-flex row" onSubmit={e => handleSubmit(e)}>
                            <div className="col-12 col-md-6">

                                <div className="my-3 mx-2">
                                    <input
                                        required
                                        type="text"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        className="form-control"
                                        placeholder="Namn" />
                                </div>
                                <div className="my-3 mx-2">
                                    <input
                                        required
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        className="form-control"
                                        placeholder="Epost" />
                                </div>
                                <div className="my-3 mx-2">
                                    <input
                                        required
                                        type="tel"
                                        value={mobile}
                                        onChange={(e) => setMobile(e.target.value)}
                                        className="form-control"
                                        placeholder="Telefon" />
                                </div>


                            </div>
                            <div className="col-12 col-md-6">
                                <div className="my-3 mx-2">
                                    <textarea
                                        maxLength="250"
                                        value={text}
                                        onChange={(e) => setText(e.target.value)}
                                        type="text"
                                        className="form-control"
                                        id="inputPassword2"
                                        placeholder="Meddelande" />
                                    <div className="my-2 mt-4 float-end">
                                        <button type="submit" className="btn bg-white mb-3 rounded-5 fw-bolder px-4 fc-headline">Skicka</button>
                                    </div>
                                </div>

                            </div>
                        </form>


                    </div>
                </div>
            </div>

        </>
    )
}

export default ContactPage
