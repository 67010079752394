import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
//Bootstrap
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import TeamActivitiesModal from './TeamActivitiesModal';
import Staff from './Staff';
import SupplementInfo from './SupplementInfo';

function BookingTable({ options, id, staffOne, staffTwo, staffThree, courses }) {
    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const [clickedOnThisID, setClickedOnThisID] = useState(false);

    const [sup1title, setSup1Title] = useState("")
    const [sup1, setSup1] = useState([])
    const [sup2title, setSup2Title] = useState("")
    const [sup2, setSup2] = useState([]);

    const [firstStaff, setFirstStaff] = useState([])
    const [secStaff, setSecStaff] = useState([])
    const [thirdStaff, setThirdStaff] = useState([])

    let name = sessionStorage.getItem("name") ? sessionStorage.getItem("name") : "";
    let mejl = sessionStorage.getItem("mail") ? sessionStorage.getItem("mail") : "";
    let phone = sessionStorage.getItem("phone") ? sessionStorage.getItem("phone") : "";
    let amount = sessionStorage.getItem("amount") ? sessionStorage.getItem("amount") : "";
    let date = sessionStorage.getItem("date") ? sessionStorage.getItem("date") : "";
    let city = sessionStorage.getItem("city") ? sessionStorage.getItem("city") : "";

    const [formData, setFormData] = useState({
        Name: sessionStorage.getItem("name") ? sessionStorage.getItem("name") : "",
        Email: sessionStorage.getItem("mail") ? sessionStorage.getItem("mail") : "",
        ParticipantNo: sessionStorage.getItem("amount") ? sessionStorage.getItem("amount") : "",
        Date: sessionStorage.getItem("date") ? sessionStorage.getItem("date") : "",
        Region: sessionStorage.getItem("city") ? sessionStorage.getItem("city") : "",
        Mobile: sessionStorage.getItem("phone") ? sessionStorage.getItem("phone") : "",
        SelectedCourse: `${id}`,
        SelectedSupplements: [],
    })

    const onChangeHandler = (e) => {
        if (e.target.name === "SelectedSupplements") {

            let copy = { ...formData };
            if (e.target.checked === true) {
                copy.SelectedSupplements.push({ "Title": e.target.value })
                setFormData(copy)
            } else {
                var index = copy.SelectedSupplements.map(function (e) { return e.Title; }).indexOf(e.target.value);

                copy.SelectedSupplements.splice(index, 1)
                setFormData(copy)
            }


        } else {

            if (e.target.name == 'Name') {
                sessionStorage.setItem('name', e.target.value);
            }
            if (e.target.name == 'Email') {
                sessionStorage.setItem('mail', e.target.value);
            }
            if (e.target.name == 'ParticipantNo') {
                sessionStorage.setItem('amount', e.target.value);
            }
            if (e.target.name == 'Date') {
                sessionStorage.setItem('date', e.target.value);
            }
            if (e.target.name == 'Region') {
                sessionStorage.setItem('city', e.target.value);
            }
            if (e.target.name == 'Mobile') {
                sessionStorage.setItem('phone', e.target.value);
            }

            setFormData(() => ({
                ...formData,
                [e.target.name]: e.target.value
            }))
        }
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const getSupplements = () => {
        const config = {
            method: "get",
            url: `${process.env.REACT_APP_BASEURL}/course-nav/${id}`,
            headers: {
                "Content-Type": "application/json",
                token: "KryastFAQ_Nykel_Mars2023",
            },
        };
        axios(config).then((res) => {

            if (res.data.allSupplements[0].groupTitle === "Lokal") {
                setSup1Title(res.data.allSupplements[0].groupTitle)
                setSup1(res.data.allSupplements[0].supplements)

                if (res.data.allSupplements[1]) {
                    setSup2(res.data.allSupplements[1].supplements)
                    setSup2Title(res.data.allSupplements[1].groupTitle)
                }
            } else {
                if (res.data.allSupplements[0].groupTitle === "Aktivitet") {
                    setSup2(res.data.allSupplements[0].supplements)
                    setSup2Title(res.data.allSupplements[0].groupTitle)
                }
            }

        });
    }


    const submitHandler = (e) => {
        e.preventDefault();

        sessionStorage.setItem('supplements', JSON.stringify(formData));
        navigate("/bokningsbekraftelse")
    }

    const getStuff = async () => {
        if (staffOne) {
            const config01 = {
                method: "get",
                url: `${process.env.REACT_APP_BASEURL}/personnel/${staffOne}`,
                headers: {
                    "Content-Type": "application/json",
                    token: "KryastFAQ_Nykel_Mars2023",
                },
            };
            axios(config01).then((res) => {

                setFirstStaff(res.data)
            });
        }

        if (staffTwo) {
            const config02 = {
                method: "get",
                url: `${process.env.REACT_APP_BASEURL}/personnel/${staffTwo}`,
                headers: {
                    "Content-Type": "application/json",
                    token: "KryastFAQ_Nykel_Mars2023",
                },
            };
            axios(config02).then((res) => {

                setSecStaff(res.data)
            });
        }

        if (staffThree) {
            const config03 = {
                method: "get",
                url: `${process.env.REACT_APP_BASEURL}/personnel/${staffThree}`,
                headers: {
                    "Content-Type": "application/json",
                    token: "KryastFAQ_Nykel_Mars2023",
                },
            };
            axios(config03).then((res) => {

                setThirdStaff(res.data)
            });
        }

    }

    useEffect(() => {
        getSupplements();
        if (staffOne) {
            getStuff();
        }
    }, [options])


    let checkedInputItem = document.getElementById('Lokal');

    const checkboxes = document.getElementsByName('SelectedSupplements')

    const checkboxesInput = checkboxes;

    const sessionStorgeInput = sessionStorage.getItem("supp3") ? sessionStorage.getItem("supp3") : "";

    const historyCheckbox = () => {
        let copy2 = { ...formData };

        if (checkboxesInput.length > 1) {


            for (let i = 0; i < checkboxesInput.length; i++) {

                //ALLA INPUTS
                // console.log("ALLA checkbox loopat", checkboxesInput[i].id)

                //ALLA TILLVAL
                // console.log(sessionStorgeInput)

                let supplementIncludes = sessionStorgeInput.includes(checkboxesInput[i].id)

                if (supplementIncludes) {
                    let checkBoxIsTrue = document.getElementById(checkboxesInput[i].id);

                    checkBoxIsTrue.checked = true;
                    copy2.SelectedSupplements.push({ "Title": checkboxesInput[i].id })
                    setFormData(copy2)
                }
            }
        }
    }

    useEffect(() => {
        historyCheckbox();
    }, [checkedInputItem])

    return (
        <div className="fixed-to-page">
            <TeamActivitiesModal
                show={show}
                clickedOnThisID={clickedOnThisID} />
            <Card className="" style={{ maxWidth: "30em" }}>
                <Card.Header>
                    {courses &&
                        <>

                            <div
                                className="mt-4"
                                dangerouslySetInnerHTML={{ __html: courses.priceDescr }}></div>
                        </>}

                </Card.Header>
                <Card.Body>
                    <form className="px-1" onSubmit={submitHandler} >

                        <div className="form-gruop mt-1">
                            <small>Namn *</small>
                            <input
                                type="text"
                                className="form-control"
                                name="Name"
                                value={name}
                                required
                                onChange={onChangeHandler} />
                        </div>
                        <div className="form-gruop mt-2">
                            <small>Mejl *</small>
                            <input
                                type="text"
                                className="form-control"
                                name="Email"
                                value={mejl}
                                required
                                onChange={onChangeHandler} />
                        </div>
                        <div className="form-gruop mt-2">
                            <small>Telefon *</small>
                            <input
                                type="text"
                                className="form-control"
                                name="Mobile"
                                value={phone}
                                required
                                onChange={onChangeHandler} />
                        </div>

                        <div className="row">
                            <div className="col">
                                <div className="form-gruop mt-2">
                                    <small>Deltagare</small>
                                    <input
                                        type="amount"
                                        className="form-control"
                                        name="ParticipantNo"
                                        value={amount}
                                        onChange={onChangeHandler} />
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-gruop mt-2">
                                    <small>Datum</small>
                                    <input
                                        type="date"
                                        className="form-control"
                                        name="Date"
                                        value={date}
                                        onChange={onChangeHandler} />
                                </div>
                            </div>
                        </div>
                        <div className="form-gruop mt-1 mb-4">
                            <small>Ort</small>
                            <input type="text"
                                className="form-control"
                                name="Region"
                                value={city}
                                onChange={onChangeHandler} />
                        </div>

                        <Staff
                            firstStaff={firstStaff}
                            secStaff={secStaff}
                            thirdStaff={thirdStaff} />
                        <hr />
                        <SupplementInfo
                            sup1={sup1}
                            sup2={sup2}
                            options={options}
                            onChangeHandler={onChangeHandler}
                        />

                        <div className="d-grid gap-2 mb-1 mt-4 text-center">
                            <Button className="bg-yellow text-dark border-0 rounded-5 btn" type="submit" size="lg">Preliminärboka</Button>
                            <small>(Denna bokningen är inte bindande)</small>
                        </div>
                    </form>

                </Card.Body>
            </Card>

        </div>
    )
}

export default BookingTable
