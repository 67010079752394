import React from 'react'
//BOOTSTRAP
import Card from 'react-bootstrap/Card';


function ConfirmComponent({ confirmContent }) {
    let supplements = [];

    if (confirmContent[2]) {
        if (confirmContent[2].fields[0].value) {
            supplements.push(confirmContent[2].fields[0].value)
        }
        if (confirmContent[2].fields[1].value) {
            supplements.push(confirmContent[2].fields[1].value)
        }
        sessionStorage.setItem('supp3', supplements)
    }

    return (
        <div className="">
            <div className="row">

                <div className="col">
                    <Card.Title className="fc-yellow fw-bolder">
                        {confirmContent[1] && confirmContent[1].groupTitle}
                    </Card.Title>

                    <Card.Text className="m-0">
                        {confirmContent[1] && confirmContent[1].fields[0].name}
                    </Card.Text>
                    <Card.Title>
                        {confirmContent[1] && confirmContent[1].fields[0].value}
                    </Card.Title>

                    <Card.Text className="m-0">
                        {confirmContent[1] && confirmContent[1].fields[1].name}
                    </Card.Text>
                    <Card.Title>
                        {confirmContent[1] && confirmContent[1].fields[1].value}
                    </Card.Title>

                    <Card.Text className="m-0">
                        {confirmContent[0] && confirmContent[0].fields[3].name}
                    </Card.Text>
                    <Card.Title>
                        {confirmContent[0] && confirmContent[0].fields[3].value}
                    </Card.Title>

                    <Card.Text className="m-0">
                        {confirmContent[1] && confirmContent[1].fields[2].name}
                    </Card.Text>

                    <Card.Title>
                        {confirmContent[1] && confirmContent[1].fields[2].value}
                    </Card.Title>
                </div>
                <div className="col">
                    <Card.Title className="fc-yellow fw-bolder">
                        {confirmContent[0] && confirmContent[0].groupTitle}
                    </Card.Title>
                    <Card.Text className="m-0">
                        {confirmContent[0] && confirmContent[0].fields[0].name}
                    </Card.Text>
                    <Card.Title>
                        {confirmContent[0] && confirmContent[0].fields[0].value}
                    </Card.Title>


                    <Card.Text className="m-0"> {confirmContent[0] && confirmContent[0].fields[1].name}</Card.Text>
                    <Card.Title>
                        {confirmContent[0] && confirmContent[0].fields[1].value}
                    </Card.Title>
                    <Card.Text className="m-0"> {confirmContent[0] && confirmContent[0].fields[2].name}</Card.Text>
                    <Card.Title>
                        {confirmContent[0] && confirmContent[0].fields[2].value}
                    </Card.Title>
                </div>
            </div>
            <hr />
            <div className="col">


                <Card.Title className="fc-yellow fw-bolder">
                    {confirmContent[2] && confirmContent[2].groupTitle}
                </Card.Title>
                <Card.Text className="m-0">
                    {/* {(confirmContent[2]) && confirmContent[2].fields[0].name} */}
                </Card.Text>
                <Card.Title>
                    {confirmContent[2] && confirmContent[2].fields[0].value.map((value, index) => (
                        <div key={index} className="my-2">- {value}</div>
                    ))}
                </Card.Title>
                {/* <Card.Text className="m-0">
                    {(confirmContent[2]) && confirmContent[2].fields[1].name}
                </Card.Text> */}
                <Card.Title>
                    {confirmContent[2] && confirmContent[2].fields[1].value.map((value, index) => (
                        <div key={index} className="my-2">- {value}</div>
                    ))}
                </Card.Title>
            </div>
        </div>

    )
}

export default ConfirmComponent
