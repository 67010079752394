import React, { useEffect, useState } from 'react'
import Info from './svg/Info';
import StaffModal from './StaffModal';

function Staff({ firstStaff, secStaff, thirdStaff }) {
    const [show, setShow] = useState(false);
    const [text, setText] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    const getStuffModal = async (num) => {
       
        if (num === 1) {
            setText(firstStaff)
            setShow(true)
        }
        if (num === 2) {
            setText(secStaff)
            setShow(true)
        }
        if (num === 3) {
            setText(thirdStaff)
            setShow(true)
        }
    }

    useEffect(() => {
        // getStuff();
    }, [])

    return (
        <div>
            <StaffModal show={show} handleClose={handleClose} textModal={text} />
            <label
                htmlFor="exampleInputPassword1"
                className="form-label text-muted mt-1">
                Handledare
            </label>
            <div>
                <div>
                    {firstStaff.subtitle &&
                        <div
                            className="btn d-flex align-items-center"
                            onClick={() => getStuffModal(1)}>
                            <div className="me-2"> {firstStaff.subtitle}</div><Info />
                        </div>}
                </div>
                <div>
                    {secStaff.subtitle &&
                        <div
                            className="btn d-flex align-items-center"
                            onClick={() => getStuffModal(2)}>
                            <div className="me-2"> {secStaff.subtitle}</div><Info />
                        </div>}
                </div>
                <div>
                    {thirdStaff.subtitle &&
                        <div
                            className="btn d-flex align-items-center"
                            onClick={() => getStuffModal(3)}>
                            <div className="me-2"> {thirdStaff.subtitle}</div><Info />
                        </div>}
                </div>
            </div>

        </div>
    )
}

export default Staff
