import React from 'react'
//BOOTSTRAP
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function SupplementModal({ show, handleClose, text }) {
    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
            size="lg">
            <Modal.Header closeButton className="text-center">
                <div className="text-center"></div>
            </Modal.Header>
            <Modal.Body className="text-center mt-4">

                {/* <h1 className="pb-2 pt-2 mt-3 fc-headline font-headline"></h1> */}

                <h3 className="pb-4">
                    {text && text.title}
                </h3>

                {text &&
                    <div
                        className=" mb-4"
                        dangerouslySetInnerHTML={{ __html: text.description }}></div>}


            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={handleClose}
                    className="rounded-5 px-4">
                    Stäng
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default SupplementModal
