import React from 'react'
import { Link, useNavigate } from "react-router-dom";

//BOOTSTRAP
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Questions({
    questions,
    setAnswerOne,
    answerOne,
    title,
    setNext,
    allInfo,
    addNeeds,
    customerOrder }) {
    let navigate = useNavigate();

    return (
        <>

            <Container>
                <Row className="my-5 text-center">
                    <h1 className="mt-5 fc-headline font-headline">{title}</h1>

                    {questions && (allInfo === "MultipleSelection") && <><h5>Max tre val</h5></>}

                </Row>
                <Row>
                    {questions && (allInfo === "SingleSelection") && questions.map(question => {
                        let spanClass = answerOne === question ? 'active btn border-0' : 'btn border-0';
                        return (
                            <Col sm={4}
                                key={question.aid}
                                className={spanClass}
                                onClick={() => setAnswerOne(question)}>
                                <div className=" p-2 mt-3 mb-2 border-0 img-fluid text-center">

                                    <img src={question.image}
                                        alt="Pratbubbla gul"
                                        className=""
                                        style={{ width: "", maxHeight: "200px", borderRadius: "5px" }} />
                                </div>
                                <p className="text-center">{question.title}</p>
                            </Col>
                        )
                    })}

                </Row>

                <Row>
                    {questions && (allInfo === "MultipleSelection") && questions.map(topping => {
                        let spanClass = customerOrder.toppings.includes(topping.aid) ? 'active h-75 btn border-0' : 'h-75 btn border-0';
                        return (
                            <Col sm={3} key={topping.aid} onClick={() => addNeeds(topping.aid)} className={spanClass}>

                                <div className="px-5 mt-3 mb-2 border-0 text-center">
                                    <div className="card d-flex align-items-center border-0">
                                        <img src={topping.image}
                                            alt=""
                                            style={{ maxWidth: "150px", maxHeight: "150px", borderRadius: "5px" }} />
                                    </div>
                                </div>
                                <p className="text-center">{topping.title}</p>
                            </Col>
                        )
                    })}

                </Row>
                <Row className="my-4 d-flex">

                    {answerOne.aid > 0 && questions && (allInfo === "SingleSelection") &&
                        <div className="next d-flex justify-content-end">
                            <Button variant="secondary" className="rounded-5 px-3" onClick={() => setNext("l")}>
                                Nästa
                            </Button>
                        </div>
                    }
                    <div className="d-flex justify-content-between">
                        {(allInfo === "MultipleSelection") &&
                            <div>
                                <a href="/frageverktyg" className="text-dark">
                                    <Button
                                        variant="secondary"
                                        className="rounded-5 px-3"
                                    >
                                        Tillbaka
                                    </Button>
                                </a>

                            </div>}
                        {customerOrder.toppings.length > 0 && (allInfo === "MultipleSelection") &&
                            <div className="next d-flex justify-content-end rounded-4">
                                <Link to="/matchandekurser">
                                    <Button variant="secondary" className="rounded-5 px-3">
                                        Nästa
                                    </Button>
                                </Link>
                            </div>
                        }
                    </div>
                </Row>

            </Container>
            <div className="space-block"></div>
        </>
    )
}

export default Questions
