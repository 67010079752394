import React, { useState, useEffect } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios';

//Bootstrap
import BookingTable from '../components/BookingTable';
import CourseContext from '../components/CourseContext';
import Button from 'react-bootstrap/Button';

function Course({ setSummary }) {
  const navigate = useNavigate();

  const { id } = useParams();
  const [courses, setCourses] = useState("");
  const [options, setOptions] = useState([]);
  const [staffOne, setStaffOne] = useState("")
  const [staffTwo, setStaffTwo] = useState("")
  const [staffThree, setStaffThree] = useState("")


  const getCourseContent = async () => {

    const config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/course/${id}`,
      headers: {
        "Content-Type": "application/json",
        token: "KryastFAQ_Nykel_Mars2023",
      },
    };

    return axios(config);
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    (async function () {
      try {
        const res = await getCourseContent();
        setCourses(res.data)
        if (res.data.pid1) {
          setStaffOne(res.data.pid1)
        }
        if (res.data.pid2) {
          setStaffTwo(res.data.pid2)
        }
        if (res.data.pid3) {
          setStaffThree(res.data.pid3)
        }
        setOptions(res.data)
      } catch (err) {
        console.log(err);
        navigate("*");
      } finally {
        // console.log("")
      }
    })();

  }, [])

  return (
    <>

      <div className="space-block"></div>

      <div className='container my-0 my-md-5'>

        <div className="row">

          <div className="col-12 col-md-7 px-md-5">
          <Button variant="secondary mb-4"
            className="rounded-5 px-3 me-2"
            onClick={() => navigate(-1)}>
            Tillbaka
          </Button>
            <CourseContext courses={courses} id={id} />
          </div>
          <div className="col-12 col-md-5 ps-md-5 ">
            {options &&
              <BookingTable
                options={options}
                id={id}
                setSummary={setSummary}
                staffOne={staffOne && staffOne}
                staffTwo={staffTwo && staffTwo}
                staffThree={staffThree && staffThree}
                courses={courses}
                />}
          </div>
        </div>
      </div>
    </>

  )
}

export default Course
