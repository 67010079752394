import React, { useEffect, useState } from 'react';
import axios from "axios";
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router";
//BOOTSTRAP
import Button from 'react-bootstrap/Button';
function MatchingCourses() {
    const navigate = useNavigate();
    const ans1 = localStorage.getItem("ans1");
    let ans2 = JSON.parse(localStorage.getItem("ans2")).filter(e => e !== 1010).filter(e => e !== 1018).filter(e => e !== 1026);
    const [coursesApi, setCoursesApi] = useState([]);
    const [coursesApiTwo, setCoursesApiTwo] = useState([]);
    const [coursesApiThree, setCoursesApiThree] = useState([]);
    const [courseTitleOne, setCoursesTitleOne] = useState([]);
    const [courseTitleTwo, setCoursesTitleTwo] = useState([]);
    const [courseTitleThree, setCoursesTitleThree] = useState([]);

    let data = {
        "Answer_1": ans1,
        "Answer_2": ans2,
        "Answer_3": [],
    };

    const getObjects = async () => {
        let arr = ans2;
        ans2 = arr.filter(e => e !== 1010);
        // console.log(arr, ans2)
        const config = {
            method: "post",
            url: `${process.env.REACT_APP_BASEURL}/questions`,
            headers: {
                "Content-Type": "application/json",
                "token": "KryastFAQ_Nykel_Mars2023"
            },
            data: JSON.stringify(data)
        }

        axios(config)
            .then(res => {
                // console.log(res.data)
                let konferens = res.data.courses[0].matchingCourses[0].title.toLowerCase();
                if (konferens.includes("konferens")) {
                    navigate("/kursval/2031");
                }
                // console.log(res.data)
                if (ans2.length > 0) {
                    // console.log(res.data)
                    setCoursesApi(res.data.courses[0].matchingCourses)
                    setCoursesTitleOne(res.data.courses[0].answerTitle)
                }
                if (ans2.length > 1) {
                    setCoursesApiTwo(res.data.courses[1].matchingCourses)
                    setCoursesTitleTwo(res.data.courses[1].answerTitle)
                }
                if (ans2.length > 2) {
                    setCoursesApiThree(res.data.courses[2].matchingCourses)
                    setCoursesTitleThree(res.data.courses[2].answerTitle)
                }
            })
    }

    useEffect(() => {
        getObjects();
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <div className=" space-block"></div>
            <div className="my-5 text-center">
                <div className="bg-lightgrey py-5">
                    <div className='container mb-5'>
                        <h1 className="mt-5 fc-headline font-headline">Matchande kurser</h1>
                        <p className="fs-6 font-headline text-secondary">Baserat på dina val har vi tagit fram de kurser som matchar bäst.</p>
                        <div className="mt-2 mb-2">

                            {ans2.length > 0 &&
                                <div className="pt-5">
                                    <div className="d-flex justify-content-center">
                                        <h4 className="text-secondary fw-bold"> {courseTitleOne}</h4>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <hr className="text-secondary w-25" />
                                    </div>
                                    {coursesApi && coursesApi.map((course, index) => (
                                        <div
                                            className="border-0 d-flex justify-content-center"
                                            key={index}>

                                            <Link
                                                to={`/kursval/${course.courseID}`}
                                                className="border-0 link-secondary text-decoration-none course-preview">

                                                <li className="kryast-li me-4">{course.title}</li>

                                            </Link>
                                        </div>

                                    ))}
                                </div>
                            }

                            {ans2.length > 1 &&
                                <div className="pt-5">
                                    <div className="d-flex justify-content-center">
                                        <h4 className="text-secondary fw-bold"> {courseTitleTwo}</h4>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <hr className="text-secondary w-25" />
                                    </div>
                                    {coursesApiTwo && coursesApiTwo.map((course, index) => (
                                        <div
                                            className="border-0 d-flex justify-content-center"
                                            key={index}>

                                            <Link
                                                to={`/kursval/${course.courseID}`}
                                                className="border-0 link-secondary text-decoration-none course-preview">

                                                <li className="kryast-li me-4">{course.title}</li>

                                            </Link>
                                        </div>
                                    ))}
                                </div>}
                            {ans2.length > 2 &&
                                <div className="pt-5">
                                    <div className="d-flex justify-content-center">
                                        <h4 className="text-secondary fw-bold"> {courseTitleThree}</h4>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <hr className="text-secondary w-25" />
                                    </div>
                                    {coursesApiThree && coursesApiThree.map((course, index) => (
                                        <div
                                            className="border-0 d-flex justify-content-center"
                                            key={index}>

                                            <Link
                                                to={`/kursval/${course.courseID}`}
                                                className="border-0 link-secondary text-decoration-none course-preview">
                                                <li className="kryast-li me-4">{course.title}</li>

                                            </Link>
                                        </div>
                                    ))}
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
            <div className=" container-sm">
                <a href="/frageverktyg" className="text-dark">
                    <Button
                        variant="secondary"
                        className="rounded-5 px-3"
                    >
                        Tillbaka
                    </Button>
                </a>

            </div>
            <div className=" space-block"></div>

        </>
    )
}

export default MatchingCourses
