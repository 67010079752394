import React from 'react';
import { Link } from "react-router-dom";
//Bootstrap
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

//Image + svg
import Chat from './svg/Chat';
import Envelope from './svg/Envelope';
import Phone from './svg/Phone';
import imageLogo from "../images/Pratbubbla.png"

function DoNotKnowModal({ show, handleClose }) {


    return (
        <>
            <Modal size="lg"
                aria-labelledby="contained-modal-title-vcenter"                 show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered>
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body >
                    <div className="d-flex justify-content-center mb-3">
                        <div className="text-center w-75 mt-2">
                            <h3>
                                <img src={imageLogo}
                                    alt="Pratbubbla gul"
                                    style={{ width: "50px", height: "50px" }} />
                            </h3>

                            <h1 className="pb-2 pt-2 mt-3 fc-headline font-headline">Vill du veta mer?</h1>
                            <p className="">Du har valt <b>“Annat/ Vet ej”</b>. Är du intresserad att få informationen på ett annat sätt eller är du osäker på vad det är du söker?</p>
                            <h5 className="mb-3">Ingen fara, vi hjälper dig!</h5>

                        </div>
                    </div>
                    <div>
                        <div className="d-flex justify-content-evenly text-center mb-4">
                            <div className="pt-3 pb-4">
                                <a
                                href="mailto: fraga@kryast.se" 
                                className="link-dark text-decoration-none">
                                    <small className="fs-5"><Envelope /></small>
                                    <div>
                                        <small className="fw-semibold">Mejla oss</small>
                                    </div>
                                    <small className="text-muted">fraga@kryast.se</small>
                                </a>
                            </div>
                            <div className="border-end me-1 ms-1"></div>
                            <div>
                                <a href="tel:0105164516" className="link-dark text-decoration-none">
                                    <div className="pt-3 pb-4">
                                        <small className="fs-5"><Phone /></small>
                                        <div>
                                            <small className="fw-semibold">Kontakta oss</small>
                                        </div>
                                        <small className="text-muted">010-5164516</small>
                                    </div>
                                </a>
                            </div>
                            <div className="border-start me-1 ms-1"></div>
                            <div>
                                <Link
                                    to="/vikontaktardig"
                                    className="link-dark text-decoration-none">
                                    <div className="pt-3 pb-4">
                                        <small className="fs-5"><Chat /></small>
                                        <div>
                                            <small className="fw-semibold">Vi kontaktar dig</small>
                                        </div>
                                        <small className="text-muted">Inom 24 timmar</small>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" className="rounded-5 px-3" onClick={handleClose}>
                        Stäng
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    )
}

export default DoNotKnowModal
